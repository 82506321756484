<template>
  <div class="FileUpload">
    <!-- <template v-if="component.upload_type === 'audio' && component.use_audio_recorder">
      <QuickEditText
        :value="label"
        _key="label"
        placeholder="Label"
        class="label"
        @update-text="$emit('update-form', $event)"
      >
        <label v-if="label" class="upload">
          {{ label }}
        </label>
      </QuickEditText>
      <VueRecordAudio mode="press" @result="onRecord" :time="10" />
      <audio
        v-if="userData && userData[component.key]"
        :src="userData[component.key].url"
        controls
      />
    </template> -->
    <UploadDragger
      name="file"
      v-bind="{ customRequest, fileList, multiple, accept: acceptFiles }"
      @change="handleChange"
      :remove="removeFile"
    >
      <p class="flex flex-row justify-center ant-upload-drag-icon">
        <Icon :icon="uploadIcon" />
        <!-- <span class="icon fas" :class="`fa-${icon || 'upload'}`" /> -->
      </p>
      <QuickEditText
        :value="label"
        _key="label"
        placeholder="Label"
        class="label"
        @update-text="$emit('update-form', $event)"
      >
        <p class="ant-upload-text">
          {{ label || `Click or drag a file to this area to upload` }}
        </p>
      </QuickEditText>
      <QuickEditText
        :value="sublabel"
        _key="sublabel"
        placeholder="Sublabel"
        class="sublabel"
        @update-text="$emit('update-form', $event)"
      >
        <p class="ant-upload-hint">
          {{
            sublabel ||
              (multiple
                ? `You can upload multiple files at once`
                : `Single File Only: Only the last file upload will be saved`)
          }}
        </p>
      </QuickEditText>
    </UploadDragger>
    <video
      v-if="userData && userData[component.key] && component.upload_type === 'video'"
      :src="userData[component.key].url"
      controls
    />
    <div
      class="validation-failed"
      v-if="
        (form.disable_form_validation || component.show_validation_messages) &&
          validationMessage &&
          !userData[component.key] &&
          hasRequested
      "
    >
      {{ validationMessage }}
    </div>
  </div>
</template>

<script>
import { unpack } from '@/helpers/computed'
import { Upload } from 'ant-design-vue'
// import { VueRecordAudio } from '@codekraft-studio/vue-record'
// import Papa from 'papaparse'

import computedValues from './editor/helpers/computedValues'
import QuickEditText from './helpers/QuickEditText.vue'
import Icon from './Icon.vue'

// const ref = new Reference(`gs://${process.env.VUE_APP_FIREBASE_STORAGE_BUCKET}`)

export default {
  name: 'FileUpload',
  components: {
    QuickEditText,
    UploadDragger: Upload.Dragger,
    // VueRecordAudio: async () => (await import('@codekraft-studio/vue-record')).VueRecordAudio,
    Icon,
  },
  inject: {
    _onUploadFile: { default: () => () => {} },
    _requestSubmitStatus: { default: () => () => [] },
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    userData: Object,
    form: Object,
  },
  data() {
    return {
      fileList: [],
      successfulFileUploads: [],
      blobfile: null,
      hasRequested: false,
    }
  },
  computed: {
    ...unpack('component', [
      'key',
      'icon',
      'label',
      'sublabel',
      'extractData',
      'uploadFile',
      'multiple',
    ]),
    file: {
      get() {
        const fallback = this.multiple ? [] : {}
        return this.componentData || fallback
      },
      set(val) {
        this.$emit('update', [this.component.key || this.componentId, val])
      },
    },
    uploadIcon() {
      return this.component.icon || 'fa-solid:upload'
    },
    acceptFiles() {
      switch (this.component.upload_type) {
        case 'audio':
          return 'audio/*'
        case 'video':
          return 'video/*'
        case 'doc':
          return '.pdf,.doc,.docx'

        default:
          return undefined
      }
    },
    validationMessage() {
      return this.getValidationMessage(this.userData[this.component.key])
    },
    requestSubmitStatus() {
      return this._requestSubmitStatus()
    },
    hasAnswer() {
      return Boolean(this.userData[this.component.key])
    },
  },
  watch: {
    multiple: {
      handler(v) {
        if (!this.file) return
        if (v) {
          if (!Array.isArray(this.file)) this.file = []
        } else {
          if (Array.isArray(this.file)) this.file = {}
        }
      },
      immediate: true,
    },
    hasAnswer: {
      handler(v) {
        if (v) this.hasRequested = false
        if (this.requestSubmitStatus && !v) this.hasRequested = true
      },
    },
    requestSubmitStatus: {
      handler(v) {
        if (v) this.hasRequested = true
      },
    },
  },
  methods: {
    getValidationMessage(key) {
      const isValid = this.component && this.component.isRequired ? Boolean(key) : true
      if (isValid) return ''
      if (!key && this.component.empty_invalid_message)
        return computedValues(this.userData, this.component.empty_invalid_message, this.form)
      if (key && this.component.invalid_message)
        return computedValues(this.userData, this.component.invalid_message, this.form)
      switch (this.component.validation_formula) {
        default:
          return 'This field is required.'
      }
    },
    removeFile(file) {
      this.fileList = this.fileList.filter(f => f.name !== file.name)
      if (this.multiple) {
        this.file = this.file.filter(f => f.name !== file.name)
      } else {
        this.file = {}
      }
    },
    async onRecord(file) {
      const key = this.component.key || this.componentId
      var S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)

      file.name = file.name || `${key}-${S4()}-${S4()}`
      // console.log(`thef file.name`, file.name)
      // this.blobfile = URL.createObjectURL(file, { type: 'audio/mpeg' }) //await file.text()
      this._onUploadFile({ file, onSuccess: () => {}, key, multiple: false })
    },
    async customRequest(upload) {
      const { file } = upload
      const fileData = { ...file, name: file.name, status: 'uploading' }
      this.file = this.multiple ? [...this.file, fileData] : fileData
      this.fileList = this.multiple ? [...this.fileList, fileData] : [fileData]

      if (this.extractData) this.handleFiles([file])

      if (this.uploadFile) {
        this._onUploadFile({
          file,
          onSuccess: this.handleSuccess,
          key: this.component.key || this.componentId,
          multiple: this.multiple,
        })
      } else {
        this.handleSuccess(file, undefined, 'done')
      }
    },
    handleSuccess(file, url, status) {
      const newFile = {
        ...file,
        name: file.name,
        uid: file.uid,
        size: file.size,
        type: file.size,
        url: url || file.url,
        status, // done || error
        response: { status: 'success' },
      }

      this.file = this.multiple
        ? this.file.map(f => (f.uid === file.uid || f.name === file.name ? newFile : f))
        : newFile

      this.fileList = this.multiple
        ? this.fileList.map(f => (f.uid === file.uid || f.name === file.name ? newFile : f))
        : [newFile]
    },
    handleChange() {
      // ref.put()
    },
    handleFiles(files) {
      // Check for the various File API support.
      if (window.FileReader) {
        // FileReader are supported.
        this.getAsText(files[0])
      } else {
        alert('FileReader are not supported in this browser.')
      }
    },
    getAsText(fileToRead) {
      var reader = new FileReader()
      // Read file into memory as UTF-8
      reader.readAsText(fileToRead)
      // Handle errors load
      reader.onload = this.loadHandler
      reader.onerror = this.errorHandler
    },
    loadHandler(event) {
      var csv = event.target.result
      this.processData(csv)
    },
    async processData(csv) {
      const Papa = await import('papaparse')
      const { data } = Papa.parse(csv, { header: true })

      this.file = { ...this.file, data }
    },
    errorHandler(evt) {
      if (evt.target.error.name == 'NotReadableError') {
        alert("Can't read file!")
      }
    },
  },
}
</script>
